import React, { useState } from 'react';
import '../styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import divider from '../images/divider-colored.svg';
import down from '../images/chevron-down.svg';
import up from '../images/chevron-up.svg';
import Layout from '../components/Layout';
import { useStaticQuery, graphql } from 'gatsby';

const EmployeeHandbook = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulEmployeeHandbook(
          sort: { fields: [weight], order: ASC }
          filter: { title: { ne: null } }
        ) {
          nodes {
            title
            weight
            icon {
              file {
                url
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `
  );

  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked == index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <>
      <Layout isWithoutHero={true}>
        <div className='handbook-page'>
          <div className='handbook-heading'>Employee Handbook</div>
          <div className='handbook-description'>
            Everything you need to know about Nuclei
          </div>
          <img src={divider} alt='divider' />
          {Object.keys(data.allContentfulEmployeeHandbook.nodes).map(i => {
            return (
              <>
                <div
                  className='handbook-title-box'
                  onClick={() => toggle(i)}
                  key={i}
                >
                  <div
                    className='handbook-icon'
                    style={{ height: '50px', height: '50px' }}
                  >
                    <img
                      style={{ height: '50px', width: '50px' }}
                      src={
                        data?.allContentfulEmployeeHandbook?.nodes[i]?.icon
                          ?.file?.url
                      }
                    />
                  </div>
                  <div className='handbook-title-arrow'>
                    <div className='handbook-title'>
                      {data.allContentfulEmployeeHandbook.nodes[i].title}
                    </div>
                    <div className='handbook-arrow'>
                      {clicked === i ? <img src={up} /> : <img src={down} />}
                    </div>
                  </div>
                </div>
                {clicked === i ? (
                  <div
                    className='handbook-drop blog-post-content'
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allContentfulEmployeeHandbook.nodes[i].body
                          .childMarkdownRemark.html,
                    }}
                  ></div>
                ) : null}
              </>
            );
          })}
        </div>
      </Layout>
    </>
  );
};

export default EmployeeHandbook;
